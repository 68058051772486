.contact {
    background-color: var(--container-color);
    border-top: 1px solid rgba(0, 0, 0, 0.1); 
}

.contact__container {
    padding: 2rem 0 6rem;
}

.contact__title, 
.contact__link {
    color: var(--title-color);
}

.contact__title {
    text-align: center;
    margin-bottom: var(--mb-2);
}

.contact__link:hover {
    color: var(--title-color-dark);
}

.contact__list {
    display: flex;
    justify-content: center;
    column-gap: 1.5rem;
    margin-bottom: var(--mb-2);
}

.contact__social {
    display: flex;
    justify-content: center;
    column-gap: 1.25rem;
}

.contact__social-icon {
    color: var(--title-color);
    font-size: 1.5rem;
    padding: 0.4rem;
}

.contact__social-icon:hover {
    color: var(--title-color-dark);
}

.contact__copy {
    display: block;
    margin-top: 4.5rem;
    color: var(--title-color);
    text-align: center;
    font-size: var(--smaller-font-size);
}

@media screen and (max-width: 992px) {
    .contact__social-icon {
        padding: 0.25rem;
        border-radius: 0.25rem;
    }
}